<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0" v-if="showName == true">Activity log ({{device.object}})</h6>
      <h6 class="mb-0" v-else>Activity log</h6>
    </template>

    <template v-if="lastLogLoaded == null" > 
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"
        >
        </b-spinner>
      </div>
    </template>
    <v-container style="max-width: 600px;" v-else>
      <v-timeline dense clipped>
        <v-slide-x-transition group>6
          <template v-for="(item, i) in events">
          <v-timeline-item 
            v-if="item.type == 'assistant'"
            :key="i"
            small
            class="mb-4"
          >
          <template v-slot:icon>
              <v-avatar color="#fff" size="30">
                <img :src="getIconPath(item.virtual_assistant)">
              </v-avatar>
          </template>

            <v-row justify="space-between">
              <v-col cols="4" font style="text-transform: uppercase">
                <b>{{ variant(item) }}</b>
              </v-col>
              <v-col class="text-right" cols="8">
                Updated at {{ item.created_at | formatDate }}
              </v-col>
            </v-row>
          </v-timeline-item>
            <v-timeline-item 
            v-else
            :key="i"
            :color="genColor(item)"
            small
            fill
            dot
            class="mb-4"
          >
            <v-row justify="space-between">
              <v-col cols="4" font style="text-transform: uppercase">
                <b>{{ variant(item) }}</b>
              </v-col>
              <v-col class="text-right" cols="8">
                Updated at {{ item.created_at | formatDate }}
              </v-col>
            </v-row>
          </v-timeline-item>
          </template>
        </v-slide-x-transition>
      </v-timeline>
      <b-button variant="primary" @click="loadMore"> Load more ...</b-button>
    </v-container>
    <b-alert
      show
      variant="secondary"
      v-if="lastLogLoaded != null && lastLogLoaded.length == 0"
      >There are no logs for this device</b-alert
    >
  </b-card>
</template>

<script>
import { FETCH_DEVICE_LOG } from "@/core/services/store/devices.module";
import { ASSISTANTS_ARRAY } from "@/core/enums/assistant-providers.enum";
import Pusher from 'pusher-js';
const COLORS = ["info", "warning", "success" , "red"];
export default {
  name: "suitch-log-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.downloadData();

  },
  data() {
    return {
      events: [],
      length: 5,
      lastLogLoaded: null,
    };
  },
  created:function(){
    this.real_time();
  },
  methods: {
    real_time () {
      let pusher = new Pusher('a41aed5f65a8c69c5f0a', { cluster: 'us2' })
      pusher.subscribe('reviews')
      pusher.bind('review_added', data => {
        console.log(">>>real_time",data);
      })
    },
    genColor(item) {
      
      if (item.type == "connection") {
        if(item.connection_type == 1){
          return "success";
        }
        else{
          return "red";
        }
      }
      if (item.type == "command") {
        return "warning";
      }
      if (item.type == "assistant") {
        return "info";
      }
      return COLORS[Math.floor(Math.random() * 3)];
    },
    variant(item){
      if (item.type == "connection"){
        if (item.connection_type == 1)
        {
          return 'ONLINE';
        }
        else{
          return 'OFFLINE';
        }
      }
      if (item.type == "command") {
        return 'COMMAND';
      }
      if (item.type == "assistant") {
        return 'ASSISTANT';
      }
      return 'UNKNOWN';
    },
    loadMore() {
    if (this.length > this.events.length) return;
    this.length = this.length + 7;   
    },
    getIconPath(assist_id) {
      if (assist_id == undefined) {
        return ASSISTANTS_ARRAY.SUITCH;
      }
      return ASSISTANTS_ARRAY[assist_id];
     },
    downloadData() {
       if (this.device != null) {
        this.$store
        .dispatch(FETCH_DEVICE_LOG, { device_token: this.device.token })
        // go to which page after successfully login
        .then((data) => {
          // download devices list
          this.events = data;
          this.lastLogLoaded = data;
        });
      }
    },
  },
  watch: {
    device: function(oldval, newval) {
      oldval, newval;
      this.downloadData();
    },
  },
};
</script>
