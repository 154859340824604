<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0" v-if="showName == true">Device Actions ({{getSafe(() => device.object)}})</h6>
      <h6 class="mb-0" v-else>Device Actions</h6>
    </template>
   
    <template v-if="lastActLoaded == null" > 
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"
        >
        </b-spinner>
      </div>
    </template>

    <b-list-group v-else>
      <template v-for="(element, i) in action_prop_table_items">
      <b-list-group-item
        v-bind:key="i"
        class="d-flex justify-content-between align-items-center"
        v-if="
            element.is_only_question == 1 || element.is_only_question == null
          "
      >
        Data to send: {{ element.command_data }}
        <b-button
          variant="danger"
          :ref="'action-button-'+i"
          @click="broadcastCommand(element,i)"
          > <b-spinner small v-show="shouldBeSpinning(i)"></b-spinner> {{ element.command }}</b-button
        >
      </b-list-group-item>
      </template>
        <b-alert show variant="secondary" v-if="action_prop_table_items.length == 0"
        >No actions created</b-alert
      >
    </b-list-group>
  </b-card>
</template>

<script>
import { FETCH_DEVICE_ACTIONS,BROADCAST_ACTION } from "@/core/services/store/devices.module";
import Pusher from 'pusher-js';
export default {
  name: "suitch-actions-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.downloadData();
  },
  data() {
    return {
      action_prop_table_items: [],
      lastActLoaded: null,
    };
  },
  created:function(){
    this.real_time();
  },
  methods: {
    getSafe,
    shouldBeSpinning(action_btn){
      if (this.$refs["action-button-"+action_btn]!=null && this.$refs["action-button-"+action_btn]!=undefined) {
        return this.$refs["action-button-"+action_btn][0].disabled;
      }
      return false;
    },
    real_time () {
      let pusher = new Pusher('a41aed5f65a8c69c5f0a', { cluster: 'us2' })
      pusher.subscribe('reviews')
      pusher.bind('review_added', data => {
        console.log(">>>real_time",data);
      })
    },
    broadcastCommand(element,action_btn){
      element,BROADCAST_ACTION;
      // disable the button
      this.$refs["action-button-"+action_btn][0].disabled=true;
      // force a render, this is a vue problem and we need to manually
      // tell vue to update the screen
      this.$forceUpdate();
      this.$store
          .dispatch(BROADCAST_ACTION, { token: this.device.token,secret:this.device.secret_token,command_data:element.command_data })
          // go to which page after successfully login
          .then((data) => {
            data;
            // just reenable the button and force update again
            this.$refs["action-button-"+action_btn][0].disabled=false;
            this.$forceUpdate();
          });
      },
    downloadData() {
      if (this.device != null) {
        this.$store
          .dispatch(FETCH_DEVICE_ACTIONS, { device_token: this.device.token })
          // go to which page after successfully login
          .then((data) => {
            // download devices list
            this.action_prop_table_items = data;
            this.lastActLoaded = data;
          });
      }
    },
  },
  computed:{},
  watch: {
    device: function(oldval, newval) {
      oldval, newval;
      this.downloadData();
    },
  },
};
function getSafe(fn) {
    try { return fn(); }
    catch (e) {
      // happy
    }
}
</script>
