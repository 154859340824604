<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0" v-if="showName == true && device!=null">Device Status ({{device.object}})</h6>
      <h6 class="mb-0" v-else>Device Status</h6>
    </template>
    
    <template v-if="statusLoaded == null" > 
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"
        >
        </b-spinner>
      </div>
    </template>
    <template v-else>
    <b-alert show variant="success" v-if="current_device.connection_type == 1"
      >ONLINE since {{ current_device.created_at | formatDate }}</b-alert
    >
    <b-alert show variant="danger" v-if="current_device.connection_type == 0"
      >OFFLINE since {{ current_device.created_at | formatDate }}</b-alert
    >
    <b-alert
      show
      variant="secondary"
      v-if="current_device.connection_type == -1"
      >Never connected or disconnected</b-alert
    >
    </template>
  </b-card>
</template>

<script>
import { FETCH_DEVICE_STATUS } from "@/core/services/store/devices.module";
export default {
  name: "suitch-status-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if(mutation.type=="setMessage"){
        // verify if the type of message is for this widget
        if(this.device!= null 
        && state.realtime.last_message.type == "status" 
        && state.realtime.last_message.token == this.device.token){
          // call again the action to get the status
          this.downloadData();
        }
      }
    });
    this.downloadData();
  },
  data() {
    return {
      current_device: { connection_type: -1 },
      statusLoaded: null,
    };
  },
  methods: {
    downloadData() {
      if (this.device != null) {
        this.$store
          .dispatch(FETCH_DEVICE_STATUS, { device_token: this.device.token })
          // go to which page after successfully login
          .then((data) => {
            // download devices list
            this.statusLoaded = {};
            if (data == undefined || data == null) {
              // when null
            } else {
              this.current_device = data;
            }
          });
      }
    },
  },
  watch: {
    device: function(oldval, newval) {
      oldval,newval;
      this.downloadData();
    },
  },
};
</script>
