<template>
  <b-container class="bv-example-row">
    <b-row>
      <b-col>
        <b-alert
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            You are seeing this door lock device
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <SuitchDetailsWidget :device="current_device"/>
        <br>
        <SuitchPropsWidget :device="current_device"/>
      </b-col>
      <b-col>
        <SuitchStatusWidget :device="current_device"/>
        <br>
        <SuitchActionsWidget :device="current_device"/>
        <br />
        <SuitchLogWidget :device="current_device"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6"> </b-col>
      <b-col cols="4">
        <!-- more information here -->
      </b-col>
    </b-row>
  </b-container>

  <!--end::Example-->
</template>

<script>
import { FETCH_SINGLE_DEVICE } from "@/core/services/store/devices.module";
import SuitchStatusWidget from "@/view/content/widgets/suitch/suitch-status-widget.vue";
import SuitchPropsWidget from "@/view/content/widgets/suitch/suitch-props-widget.vue";
import SuitchLogWidget from "@/view/content/widgets/suitch/suitch-log-widget.vue";
import SuitchActionsWidget from "@/view/content/widgets/suitch/suitch-actions-widget.vue";
import SuitchDetailsWidget from "@/view/content/widgets/suitch/suitch-details-widget.vue";


export default {
  data() {
    return {
      global_wizard: {},
      current_device: null,
    };
  },
  
  computed: {
  },

  components: {
    SuitchStatusWidget,
    SuitchPropsWidget,
    SuitchLogWidget,
    SuitchActionsWidget,
    SuitchDetailsWidget,

  },

  methods: {
    
   
  },
  mounted() {
    // console.log("route:",this.$route.params);
    const device_token = this.$route.params.token;

    this.$store
      .dispatch(FETCH_SINGLE_DEVICE, { device_token })
      // go to which page after successfully login
      .then((data) => {
        // download devices list
        this.current_device = data;
      });
    },
  };
</script>
