export const ASSISTANTS = {
    ALEXA: "media/logos/amazon.png",
    GOOGLE: "media/logos/google-home.png",
    HOME: "media/logos/google-home.png",
    NEST: "media/logos/nest.png",
    ACURITE: "media/logos/acurite.png",
    SUITCH: "media/logos/logo-letter-1.png",
    SIRI: "media/logos/siri-64.png"
};

// # suitch = 0
//     # siri = 1
//     # google = 2
//     # alexa = 3
//     # nest = 4
//     # acurite = 5
export const ASSISTANTS_ARRAY = {
    0: ASSISTANTS.SUITCH,
    1: ASSISTANTS.SIRI,
    2: ASSISTANTS.GOOGLE,
    3: ASSISTANTS.ALEXA,
    4: ASSISTANTS.NEST,
    5: ASSISTANTS.ACURITE
}